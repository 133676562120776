<template>
  <div class="page">
    <Navbar title="车辆信息" :callback="back" type="SMP" />
    <div class="list">
      <van-row class="title">
        <van-col span="12">品牌型号</van-col>
        <van-col span="6">价值(万)</van-col>
        <van-col span="6">操作</van-col>
      </van-row>
      <van-row class="record" v-for="car in carList" :key="car.id">
        <van-col span="12">{{ car.brand }}</van-col>
        <van-col span="6">{{ car.worth }}</van-col>
        <van-col span="6"
          ><van-tag :color="COLOR" plain @click="updateCar(car.seq)"
            >修改</van-tag
          >&nbsp;
          <van-tag type="danger" plain @click="deleteCar(car.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="no-record" v-if="carList.length <= 0">
        <van-col span="24">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image
        ></van-col>
      </van-row>
    </div>
    <van-row class="btn">
      <van-col span="24">
        <van-button :color="COLOR" icon="plus" size="small" @click="createCar()"
          >新增车辆</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      personCode: '',
      carList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveCarList()
  },
  methods: {
    async retrieveCarList () {
      var pd = { personCode: this.personCode, group: 'CAR', type: 'DTL' }
      this.carList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var car = { seq: element.seq, brand: content.brand, worth: content.worth }
          this.carList.push(car)
        })
      }
    },
    createCar () {
      this.$router.push({ path: '/mde/people/person/dtlCarEdit', query: { personCode: this.personCode, seq: this.carList.length + 1 } })
    },
    updateCar (seq) {
      this.$router.push({ path: '/mde/people/person/dtlCarEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteCar (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除记录吗?'
      })
        .then(() => {
          this.deleteCarExecute(seq)
        })
    },
    async deleteCarExecute (seq) {
      var pd = { key: 'PSN_CAR_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveCarList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  margin: 5px;
  .title {
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .record {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
  }
  .no-record {
    margin: 30px auto;
  }
}
.btn {
  margin-top: 30px;
}
</style>
